import React from 'react';

import Image from 'next/image'
import Link from 'next/link'

import AppLogo from '../../../public/assets/img/app-logo.png';

import Footer from '@/kyna/includes/Footer';

const Custom404View = () => {
    return (
        <div>
            <div className="xl:container mx-auto px-10 pt-20 md:pt-36">
                <div className="md:flex">
                    <div className='pr-10'>
                        <Link href="/"><a className='w-24 h-24 mt-0 my-10 block'><Image src={AppLogo} alt="AppLogo" /></a></Link>
                    </div>
                    <div>
                        <h1 className='text-lg uppercase text-blue mt-0 font-Typold'>404 Error</h1>

                        <h2 className='text-5xl font-Typold font-extrabold'>This page does not exist.</h2>
                        <p className='text-lg text-gray-500'>The page you are looking for could not be found.</p>

                        <div className="mt-10">
                            <Link href="/">
                                <a className='inline-flex items-center'>
                                    <div className='pr-2'>Go back to home</div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>

                
                {/* <div className="border h-0.5 border-gray-100"></div> */}
            </div>
            

            <>  
                <Footer />
            </>
        </div>
    );
};

export default Custom404View;